.light {
  width: 100%;
  background-image: linear-gradient(
    to right,
    #aaa497,
    #aaa497 100px,
    #bf8176 100px,
    #bf8176 120px,
    #eaeadf 120px
  );

  @media screen and (max-width: 52em) {
    background-image: linear-gradient(
      to right,
      #aaa497,
      #aaa497 30px,
      #bf8176 30px,
      #bf8176 40px,
      #eaeadf 40px
    );
  }
}

.dark {
  width: 100%;
  background-image: linear-gradient(
    to right,
    #bf8176,
    #bf8176 80px,
    #f2debd 80px,
    #f2debd 100px,
    #364e54 100px
  );

  @media screen and (max-width: 1000px) {
    background-image: linear-gradient(
      to right,
      #bf8176,
      #bf8176 7%,
      #f2debd 7%,
      #f2debd 9%,
      #364e54 9%
    );
  }
}
